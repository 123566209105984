import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router-dom';
import api from '../../api';
import LoadSpinner from '../loadSpinner/LoadSpinner';
import { AxiosError } from 'axios';
const LatestResultRedirect: React.FC = () => {
  const {
    scheduleId
  } = useParams<{
    scheduleId: string;
  }>();
  const history = useHistory();
  useEffect(() => {
    const fetchLatestResult = async () => {
      try {
        console.log(`Fetching latest result set for schedule ID: ${scheduleId}`);
        const response = await api.schedules.latestResultSet(Number(scheduleId));
        if (response.data && response.data.id) {
          console.log('Latest result set found:', response.data);
          const {
            checklist_id,
            id: resultSetId
          } = response.data;
          history.replace(`/checklists/${checklist_id}/audit/${resultSetId}`);
        } else {
          console.warn(`No latest result set available for schedule ID: ${scheduleId}`);
          toast('No latest result set available for this schedule.', {
            type: 'error'
          });
        }
      } catch (error: unknown) {
        console.error('Error fetching latest result set:', error);
        if (error instanceof AxiosError && error.response) {
          console.error('API response error:', error.response.status, error.response.data);
          if (error.response.status === 401) {
            console.error('Unauthorized access. Redirecting to login page.');
            toast('You are not logged in. Please log in to access this feature.', {
              type: 'error'
            });
            history.push('/login');
          } else if (error.response.status === 403) {
            console.warn('Forbidden access. User does not have permission.');
            toast('You do not have permission to access this feature.', {
              type: 'error'
            });
          } else {
            console.error('Unexpected API response error:', error.response.status);
            toast('An unexpected error occurred. Please try again later.', {
              type: 'error'
            });
          }
        } else {
          console.error('Network or unexpected error occurred:', error);
          toast('An unexpected error occurred. Please check your network connection.', {
            type: 'error'
          });
        }
      }
    };
    fetchLatestResult();
  }, [scheduleId, history]);
  return <LoadSpinner className="centered" />;
};
export default LatestResultRedirect;