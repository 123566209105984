import { Tooltip } from '@mui/material';
import { Tag } from 'bloomer';
import clsx from 'clsx';
import { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { setNextInspectionsSort } from '../../../actions/sortActions';
import api from '../../../api';
import { NextInspectionDto } from '../../../api/types/checklist';
import { FilterSettings } from '../../../reducers/filterReducer';
import { ISortSetting } from '../../../reducers/sortReducer';
import { IAlphaprocessState } from '../../../store';
import ITranslationProps from '../../../types/translationProps';
import LoadSpinner from '../../loadSpinner/LoadSpinner';
import DataTable from '../../widgets/DataTable';
import { IDataTableHeader } from '../../widgets/DataTableHeader';
interface IState {
  /**
   * Raw data from the backend
   */
  scheduledChecklists: NextInspectionDto[];
  /**
   * Sorted and filtered data
   */
  processedData: NextInspectionDto[];
  isLoading: boolean;
  error: any;
  currentPage: number;
}
interface IStateProps {
  sortSettings: ISortSetting;
  isAdmin: boolean;
  filters: FilterSettings;
}
interface IDispatchProps {
  setSort: (setting: ISortSetting) => void;
}
type Props = ITranslationProps & IStateProps & IDispatchProps & RouteComponentProps;

/**
 * Items per page
 */
const PER_PAGE = 15;

/**
 * Table containing all scheduled checklists
 */
class ScheduledChecklistsTable extends Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      scheduledChecklists: [],
      processedData: [],
      isLoading: true,
      error: null,
      currentPage: 1
    };
  }
  public async componentDidMount() {
    try {
      const {
        data
      } = await api.schedules.nextInspections();
      this.setState({
        scheduledChecklists: data
      }, () => this.filterAndSortData());
    } catch (e) {
      this.setState({
        error: e
      });
    } finally {
      this.setState({
        isLoading: false
      });
    }
  }
  public componentDidUpdate(prevProps: Props) {
    if (prevProps.filters.term !== this.props.filters.term || prevProps.filters.selectedProjects !== this.props.filters.selectedProjects || prevProps.filters.startDate !== this.props.filters.startDate || prevProps.filters.endDate !== this.props.filters.endDate || prevProps.sortSettings.direction !== this.props.sortSettings.direction || prevProps.sortSettings.column !== this.props.sortSettings.column) {
      this.filterAndSortData();
    }
  }
  private filterAndSortData() {
    const {
      scheduledChecklists
    } = this.state;
    const {
      filters,
      sortSettings
    } = this.props;
    let data = [...scheduledChecklists];

    // Apply filters
    if (filters.term) {
      data = data.filter(x => x.title.toLocaleLowerCase().includes(filters.term) || x.project_name && x.project_name.toLocaleLowerCase().includes(filters.term));
    }
    if (filters.selectedProjects.length) {
      const projectIds = filters.selectedProjects.map(x => x.value);
      data = data.filter(x => projectIds.includes(x.project_id));
    }
    if (filters.startDate) {
      data = data.filter(x => new Date(x.start_date) >= new Date(filters.startDate));
    }
    if (filters.endDate) {
      data = data.filter(x => new Date(x.start_date) <= new Date(filters.endDate));
    }

    // Apply sorting
    if (sortSettings.column === 'title') {
      data = data.sort((a, b) => a.title.localeCompare(b.title));
    } else if (sortSettings.column === 'start_date') {
      data = data.sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime());
    } else if (sortSettings.column === 'due_date') {
      data = data.sort((a, b) => new Date(a.due_date).getTime() - new Date(b.due_date).getTime());
    } else if (sortSettings.column === 'project') {
      data = data.sort((a, b) => a.project_name ? a.project_name.localeCompare(b.project_name) : 0);
    }
    if (sortSettings.direction === 'desc') {
      data = data.reverse();
    }

    // Reset to page 1 when filtering changes
    this.setState({
      processedData: data,
      currentPage: 1
    });
  }
  private handlePagination = (page: number) => {
    this.setState({
      currentPage: page
    });
  };
  private handleSort = (field: string, direction: 'asc' | 'desc') => {
    this.props.setSort({
      column: field,
      direction
    });
  };
  private async handleLatestResultClick(scheduleId: number) {
    try {
      // Show a loading indicator if necessary
      const response = await api.schedules.latestResultSet(scheduleId);
      if (response.data && response.data.id) {
        // Redirect to the checklist view for the result set if it exists
        const checklistId = response.data.checklist_id;
        const resultSetId = response.data.id;
        const url = `/checklists/${checklistId}/audit/${resultSetId}`;
        this.props.history.push(url); // Assuming you're using react-router
      } else {
        // Handle case when no latest result exists (optional)
        alert('No latest result set available for this schedule.');
      }
    } catch (error) {
      // Handle API errors (e.g., network issues, server errors)
      console.error('Error fetching latest result set:', error);
      alert('An error occurred while fetching the latest result set.');
    }
  }
  public render() {
    const {
      sortSettings,
      t
    } = this.props;
    const spinner = this.state.isLoading ? <LoadSpinner className="centered" /> : null;
    const begin = (this.state.currentPage - 1) * PER_PAGE;
    const scheduledChecklistsData = this.state.processedData.slice(begin, begin + PER_PAGE).map(x => ({
      title: <div className={x.autostart && 'has-not-allowed-cursor'}>
            <Link className={clsx('has-hyphens', 'is-inline-flex-mobile', x.non_working_day_excluded && 'has-text-strike-through', (x.autostart || x.non_working_day_excluded) && 'is-disabled')} to={`/resultset/${x.checklist_id}/new?due_date=${encodeURI((x.due_date as any))}` + `&project_id=${x.project_id}&tester_id=${x.user_ids.join(',')}&title=${encodeURI(x.title)}&team_ids=${x.team_ids.join(',')}&location=${x.location}&contact_id=${x.contact_id}&resource_id=${x.ressource_id}` + `&schedule_id=${x.schedule_id}`}>
              {x.title}
              {x.rescheduled_due_to_non_working_day && <Tooltip title={t('schedule.rescheduledTooltip')}>
                  <InfoIcon sx={{
              height: '0.75em',
              color: 'orange'
            }} />
                </Tooltip>}
            </Link>
            {x.non_working_day_excluded && <Tooltip title={t('schedule.nonWorkingDayTooltip')}>
                  <InfoIcon sx={{
            height: '0.75em'
          }} />
                </Tooltip>}
            {x.autostart && <Tag className="has-margin-left-0 is-autostart-tag" isColor="yellow">
              {t('overview.startsAutomatically')}
            </Tag>}
          </div>,
      latest_result: <div>
          <Link to="#" onClick={e => {
          e.preventDefault(); // Prevent default link behavior
          this.handleLatestResultClick(x.schedule_id); // Call a function to handle fetching
        }} className="latest-result-link">
            <Tooltip title={t('overview.latestResultLink')}>
              <i className="fas fa-arrow-up-right-from-square"></i> 
            </Tooltip>
          </Link>          
        </div>,
      due_date: x.due_date,
      start_date: x.start_date,
      rawTitle: x.title,
      id: `${x.schedule_id}-${x.due_date}`,
      repeat: x.repeat,
      project: <div className="is-ellipsed action-table-project">{x.project_name ? x.project_name : ''}</div>,
      rawProject: x.project_name ? x.project_name : '',
      project_id: x.project_id
    }));
    const headers: IDataTableHeader[] = [{
      field: 'title',
      label: t('resultSet.title'),
      width: '30%'
    }, {
      field: 'latest_result',
      label: t('resultSet.latestResult'),
      width: '10%'
    }, {
      field: 'start_date',
      label: t('schedule.fields.startDate'),
      formatAs: 'dueDate',
      width: isMobile ? '30%' : '10%'
    }, {
      field: 'due_date',
      label: t('resultSet.dueShort'),
      formatAs: 'dueDate',
      width: isMobile ? '30%' : '10%'
    }];
    if (!isMobile) {
      headers.push({
        field: 'repeat',
        label: 'Intervall',
        width: '10%'
      });
      headers.push({
        field: 'project',
        label: 'Projekt',
        width: '20%'
      });
    }
    return <>
        {spinner}
        <DataTable isLoading={this.state.isLoading} data={scheduledChecklistsData} actions={[]} headers={headers} currentPage={this.state.currentPage} onPagination={this.handlePagination} onSort={this.handleSort} sortColumn={sortSettings.column} sortDir={sortSettings.direction} totalPages={Math.floor(this.state.processedData.length / PER_PAGE)} />
      </>;
  }
}
const mapState = (state: IAlphaprocessState): IStateProps => ({
  sortSettings: state.sort.nextInspectionsSort,
  isAdmin: state.core.user?.current_role === 'admin',
  filters: state.filter.checklistFilterSettings
});
const mapDispatch = (dispatch: (x: any) => void): IDispatchProps => ({
  setSort: settings => dispatch(setNextInspectionsSort(settings))
});
export default connect(mapState, mapDispatch)(withTranslation()(withRouter(ScheduledChecklistsTable)));