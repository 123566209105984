/* eslint-disable max-len */
import { lazy, Suspense } from 'react';
import { withRouter } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { v4 } from 'uuid';
import LoadSpinner from '../components/loadSpinner/LoadSpinner';
import { useHasCompany, useIsLoggedIn, useIsPowerUser } from '../hooks/authHooks';
import ChartIndex from '../pages/charts/ChartIndex';
import ChecklistOverview from '../pages/checklists/ProcessOverview';
import SyncOfflineChecklist from '../pages/checklists/SyncOfflineChecklist';
import { NotFound, Unauthorized } from '../pages/errors';
import ReportFailed from '../pages/errors/ReportFailed';
import Home from '../pages/Home';
import { EditTeam, TeamInvite } from '../pages/teams';
import { privateRoutes, publicRoutes, restrictedRoutes } from '../util/routeDefinitions';
import AsyncErrorBoundary from './AsyncErrorBoundary';
import FromOverviewToChecklist from './redirects/FromOverviewToChecklists';
import NoLicense from './users/NoLicense';
import CustomLoadable from './CustomLoadable';
import LatestResultRedirect from './redirects/LatestResultRedirect';
const LazyReportView = lazy(() => import( /* webpackChunkName: "report" */'../pages/ReportPage'));
export function ReportView(props) {
  return <AsyncErrorBoundary>
      <Suspense fallback={<LoadSpinner className="centered" />}>
        <LazyReportView {...props} />
      </Suspense>
    </AsyncErrorBoundary>;
}
const LazyChat = lazy(() => import( /* webpackChunkName: "chat" */'../pages/chat/Chat'));
export function Chat(props) {
  return <AsyncErrorBoundary>
      <Suspense fallback={<LoadSpinner className="centered" />}>
        <LazyChat {...props} />
      </Suspense>
    </AsyncErrorBoundary>;
}
const LazyBoardIndex = lazy(() => import( /* webpackChunkName: "boards" */'../pages/boards/BoardIndex'));
export function BoardIndex(props) {
  return <AsyncErrorBoundary>
      <Suspense fallback={<LoadSpinner className="centered" />}>
        <LazyBoardIndex {...props} />
      </Suspense>
    </AsyncErrorBoundary>;
}
const LazyChartEditor = lazy(() => import( /* webpackChunkName: "chartEditor" */'../pages/charts/ChartEditor'));
export function ChartEditor(props) {
  return <AsyncErrorBoundary>
      <Suspense fallback={<LoadSpinner className="centered" />}>
        <LazyChartEditor {...props} />
      </Suspense>
    </AsyncErrorBoundary>;
}
const LazyLogin = lazy(() => import( /* webpackChunkName: "login" */'../pages/auth/Login'));
export function Login(props) {
  return <AsyncErrorBoundary>
      <Suspense fallback={<LoadSpinner className="centered" />}>
        <LazyLogin {...props} />
      </Suspense>
    </AsyncErrorBoundary>;
}
const LazyCalender = lazy(() => import( /* webpackChunkName: "calendar" */'../pages/calendar/Calendar'));
export function Calendar(props) {
  return <AsyncErrorBoundary>
      <Suspense fallback={<LoadSpinner className="centered" />}>
        <LazyCalender {...props} />
      </Suspense>
    </AsyncErrorBoundary>;
}
function PrivateRoute({
  requireCompany = true,
  forceReload = false,
  component: Component,
  ...rest
}) {
  const isAuthenticated = useIsLoggedIn();
  const hasCompany = useHasCompany();
  let mustRedirect = !isAuthenticated;
  let pathname = '/login';
  if (!mustRedirect && requireCompany && !hasCompany) {
    mustRedirect = true;
    pathname = '/corporation/new';
  }
  return <Route {...rest} render={props => !mustRedirect ? <Component key={forceReload ? v4() : undefined} {...props} /> : <Redirect to={{
    pathname,
    state: {
      from: props.location
    }
  }} />} />;
}
function RestrictedRoute({
  requireCompany = true,
  component: Component,
  ...rest
}) {
  const isAuthenticated = useIsLoggedIn();
  const hasCompany = useHasCompany();
  const isPowerUser = useIsPowerUser();
  let mustRedirect = !isAuthenticated;
  let pathname = '/login';
  if (!mustRedirect && requireCompany && !hasCompany) {
    mustRedirect = true;
    pathname = '/corporation/new';
  }
  return <Route {...rest} render={props => {
    if (mustRedirect) {
      return <Redirect to={{
        pathname,
        state: {
          from: props.location
        }
      }} />;
    }
    if (!isPowerUser) {
      return <Unauthorized />;
    }
    return <Component {...props} />;
  }} />;
}
function Routes() {
  return <Switch>
      {publicRoutes.map(route => <Route key={route.path} exact={route.exact} path={route.path} component={CustomLoadable({
      loader: route.loader
    })} />)}

      {privateRoutes.map(route => <PrivateRoute key={route.path} path={route.path} forceReload={route.forceReload} exact={route.exact} component={CustomLoadable({
      loader: route.loader
    })} requireCompany={route.requireCompany} />)}

      {restrictedRoutes.map(route => <RestrictedRoute key={route.path} path={route.path} forceReload={route.forceReload} exact={route.exact} component={CustomLoadable({
      loader: route.loader
    })} />)}
      <PrivateRoute path="/" exact component={Home} forceReload />
      <PrivateRoute path="/teams/edit/:id" component={EditTeam} />
      <PrivateRoute path="/no_license" component={NoLicense} />
      <RestrictedRoute path="/team/invite" component={TeamInvite} />
      <PrivateRoute path="/chat" exact component={Chat} />
      <PrivateRoute path="/chat/new" exact component={Chat} />
      <PrivateRoute path="/chat/:conversationKey" exact component={Chat} />
      <PrivateRoute path="/checklists/offline/sync/:id" exact component={SyncOfflineChecklist} />
      <Route path="/checklists/tab/:tabIndex/folders/:folderId" component={ChecklistOverview} exact />
      <Route path="/checklists/tab/:tabIndex/folders/:folderId/:checklistId/audit/:id/" component={ChecklistOverview} exact />
      <Route path="/checklists/tab/:tabIndex/folders/:folderId/:checklistId/audit/:id/areas/:areaId/position/:areaPosition" component={ChecklistOverview} exact />
      <Route path="/checklists/tab/:tabIndex/:id?" component={ChecklistOverview} exact />
      <Route path="/checklists/:templateId/audit/:id" component={ChecklistOverview} exact />
      <Route path="/checklists/:id?" component={ChecklistOverview} exact />
      <Route path="/checklists/:templateId/audit/:id" component={ChecklistOverview} exact />
      <Route path="/checklists/:checklistId/audit/:id/areas/:areaId/position/:areaPosition" component={ChecklistOverview} exact />
      <PrivateRoute path="/schedules/:scheduleId/latest_result" component={LatestResultRedirect} />
      <PrivateRoute path="/checklists/:id/audit/:resultSetId/report" component={ReportView} exact />
      <PrivateRoute path="/overview/checklists/:id?" exact component={Home} />
      <PrivateRoute path="/tab/:tabIndex" component={Home} exact forceReload />
      <PrivateRoute path="/overview/checklists/:templateId/audit/:id" component={Home} exact />
      <PrivateRoute path="/overview/checklists/:checklistId/audit/:id/areas/:areaId/position/:areaPosition" component={Home} exact />
      <Route path="/overview/checklists/tab/:tabIndex/folders/:folderId/:checklistId/audit/:id/areas/:areaId/position/:areaPosition" component={FromOverviewToChecklist} exact />
      <PrivateRoute path="/charts" component={ChartIndex} exact />
      <PrivateRoute path="/charts/:id" component={ChartEditor} exact />
      <PrivateRoute path="/boards" component={BoardIndex} exact />
      <PrivateRoute path="/calendar" component={Calendar} exact />
      <PrivateRoute path="/dashboard" component={<Redirect to="/" />} exact />
      <Route path="/unauthorized" component={Unauthorized} />
      <Route path="/report_failed" component={ReportFailed} />
      <Route component={NotFound} />
    </Switch>;
}
export default withRouter(Routes);